import React, { Component } from "react"
// import ReactDOM from 'react-dom';
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { minWidth } from "../themes/default"

import Holder from "../components/utils/Holder"
import InnerHolder from "../components/utils/InnerHolder"
import ImageBackground from "../components/utils/ImageBackground"

import styled from "styled-components"
// import Youtube from "../components/utils/Youtube";
import YoutubeR from "../components/utils/YoutubeR"

// https://react-lazy-youtube.now.sh/
// https://www.npmjs.com/package/react-lazy-youtube
// https://awesometalks.party/
// import Player from 'react-lazy-youtube'
// TODO: lazy load video consider this: https://github.com/twobin/react-lazyload

class ProfileTemplate extends Component {
  render() {
    const currentProfile = this.props.data.wordpressWpProfiles

    const cleanExcerpt = currentProfile.excerpt.replace(/<\/?[^>]+(>|$)/g, "")
    const cleanTitle = currentProfile.title.replace(/<\/?[^>]+(>|$)/g, "")
    const img = currentProfile.featured_media.localFile.childImageSharp.fixed
    const field = currentProfile.acf
    const background = field.background_image.localFile.childImageSharp.fluid

    return (
      <Layout>
        <SEO title={cleanTitle} description={cleanExcerpt} />

        <Holder textAlign="center">
          <InnerHolder>
            <Title dangerouslySetInnerHTML={{ __html: currentProfile.title }} />

            {/* { field.video && 
              <Youtube title={cleanTitle} src={field.video} />
            } */}

            {field.video && <YoutubeR id={field.video} />}

            <Text>
              {/* {currentProfile} */}
              {field.content && (
                <InnerText>
                  <Float>
                    <Img fixed={img} />
                    {field.role && <div className="role">{field.role}</div>}
                  </Float>

                  <div dangerouslySetInnerHTML={{ __html: field.content }} />
                </InnerText>
              )}

              {field.current_city && (
                <InnerText className="">
                  Current City: {field.current_city}
                </InnerText>
              )}

              {field.favorite_city && (
                <InnerText className="">
                  Favourite City: {field.favorite_city}
                </InnerText>
              )}

              {field.countried_traveled && (
                <InnerText className="">
                  Countries Traveled: {field.countried_traveled}
                </InnerText>
              )}

              {/* current_city
            favorite_city
            countried_traveled
            */}

              {/* <div dangerouslySetInnerHTML={{ __html: currentProfile.excerpt }} /> */}

              {/* <div className="bkg-overlay">
              {background && 
                <div><Img fluid={background} /></div>
              }
            </div> */}
            </Text>
          </InnerHolder>

          <ImageBackground hasDB fluid={background} darkerby="70" />
        </Holder>
      </Layout>
    )
  }
}

export default ProfileTemplate

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`

const Text = styled.div`
  background: rgba(255, 255, 255, 0.7);
  color: ${props => props.theme.color.dark};
  overflow: hidden;
  position: relative;
  padding-bottom: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`

const InnerText = styled.div`
  padding: 0.5rem 1rem;
  margin: 0;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
`

const Float = styled.div`
  margin: 0 auto 20px;
  width: 100%;
  text-align: center;

  @media ${minWidth.tablet} {
    margin-right: 2rem;
    overflow: hidden;
    width: auto;
    float: left;
  }

  @media ${minWidth.desktopPlus} {
    position: absolute;
    left: 2.75rem;
    top: 2rem;
  }

  .role {
    border-bottom: 1px solid ${props => props.theme.color.dark};
    width: 100%;
    text-align: center;
  }
`

export const profileQuery = graphql`
  query($id: String!) {
    wordpressWpProfiles(id: { eq: $id }) {
      title
      excerpt
      date(formatString: "MMMM DD, YYYY")
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fixed(width: 200, height: 200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }

      acf {
        content
        role
        current_city
        favorite_city
        countried_traveled
        video
        background_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
