import React from 'react';
import NetworkInformation from 'react-network-info'
import YouTube from 'react-youtube'
import styled from 'styled-components'
import CTA from '../../components/utils/CTA'

class YoutubeR extends React.Component {

  state = {
    youtube: false,
    youtubebkkg: 'show'
  }

  onClickHandler =  () => {
    this.setState({
      youtube: true,
      youtubebkkg: 'hide'
    })
   
    
  }

  render() {
    const opts = {
      height: '390',
      width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 1
      }
    };

    const {id} = this.props

    const url = `https://img.youtube.com/vi/${id}/original.jpg` // hqdefault
    const youtubeUrl = `https://www.youtube.com/watch?v=${id}` 
 
    return (
      <NetworkInformation>
      {props => 
        props.effectiveType === '4g' ? (
        <FakePlayer>
          <div 
            className={this.state.youtubebkkg} 
            onClick={ this.onClickHandler} 
            style={{ width: '100%', height: '100%',  cursor: 'pointer', position: 'absolute', top: 0, left: 0 }}
          >
            <div className="play-button" />
            <img src={url} />
            
          </div>

          { this.state.youtube && 
            <YouTube
              videoId={id}
              opts={opts}
              //onReady={this._onReady}
            />
          }

        </FakePlayer>
        ) : ( <CTA url={youtubeUrl} text="See Video" /> )
        
      }
      </NetworkInformation>
    );
  }
 
  // _onReady(event) {
  //   // access to player in all event handlers via event.target
  //   event.target.pauseVideo();
  // }
}

export default YoutubeR

const FakePlayer = styled.div`
    background-color: #000;
    margin-bottom: 30px;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    cursor: pointer;
    transition: all .25s cubic-bezier(0.0,0.0,0.2,1);
    width:100%;
    height:0;

    .play-button {
      width: 68px;
      height: 48px;
      background-color: #333;
      box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
      z-index: 1;
      opacity: 0.9;
      border-radius: 6px;
      transition: all .25s cubic-bezier(0.0,0.0,0.2,1);
      top: 50%;
      left: 50%;
      transform: translate3d( -50%, -50%, 0 );
      position: absolute;

      &:before {
        content: "";
        border-style: solid;
        border-width: 12px 0 12px 20.0px;
        border-color: transparent transparent transparent #fff;
        top: 50%;
        left: 50%;
        transform: translate3d( -50%, -50%, 0 );
        position: absolute;
      }
    }

    img {
        width: 100%;
        // top: -16.82%;
        top: 0;
        left: 0;
        opacity: 0.7;
        position: absolute;
      }

      iframe {
        width:100% !important;
        height: 100% !important;
        position: absolute;
        top:0;
        left:0;
      }

      .hide {
        display: none;
      }
`


