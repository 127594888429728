import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

const InnerHolder = (props) => 

<Inner>
  {props.children}
</Inner>

export default InnerHolder


// props


InnerHolder.propTypes = {
  /**  */
  children: PropTypes.node.isRequired,
 
}

// styles
const Inner = styled.div`
  width: 100%;
  max-width: ${props=> props.theme.wide.desktopPlus};
  margin: 0 auto;
  position: relative;
  z-index:5;
`
