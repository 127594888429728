import React from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

// https://polished.js.org/docs/#installation
import { darken } from 'polished'
const CTA = (props) => 
  <Button>
    <a href={props.url}>{props.text}</a>
  </Button>


//props
CTA.defaultProps = {
  url: '#',
  text: 'add CTA text here'
}

CTA.propTypes = {
  /** URL as string and required. */
  url: PropTypes.string.isRequired,
  /** CTA Text */
  text: PropTypes.string.isRequired,
}

export default CTA




// styles

const Button = styled.div`
  display: flex;
  text-align:center;
  width:100%;
  box-sizing: border-box;
  padding:1rem;

  a {
    background: ${props => props.theme.color.red};
    color: ${props => props.theme.color.white};
    padding:5px 1rem;
    margin:auto;
    text-transform: uppercase;
    transition: all 0.3s ease-in;
    text-decoration: none !important
  }

  a:hover {
    background: ${ props => darken(0.20, props.theme.color.red) }
  }
  
`
